import React from "react";
import Navbar from "../Includes/Navbar";
import Footer from "../Includes/Footer";
import afterImage from "../Images/img/s-image.png";
import service1 from "../Images/img/s1.png";
import service2 from "../Images/img/s2.png";
import service3 from "../Images/img/s3.png";
import service4 from "../Images/img/s4.png";
import service5 from "../Images/img/s5.png";
import service6 from "../Images/img/s6.png";
import service7 from "../Images/img/s7.png";
// import service5 from "../Images/img/s5.png";
// import service6 from "../Images/img/s6.png";
import { Link } from "react-router-dom/dist";

export default function Service() {
  return (
    <>
      <Navbar />
      {/* <!-- Hero Start --> */}
      <div className="container-fluid bg-primary py-5 hero-header mb-5">
        <div className="row py-3">
          <div className="col-12 text-center">
            <h1 className="display-3 text-white animated zoomIn">Services</h1>
            <Link to="/" className="h4 text-white">
              Home
            </Link>
            <span className="text-white px-2">∕</span>
            <a
              href="#"
              className="h4 text-white disabled"
              aria-disabled="true"
              style={{
                pointerEvents: "none",
                textDecoration: "none",
                color: "gray",
                opacity: "0.5",
              }}
            >
              Services
            </a>
          </div>
        </div>
      </div>
      {/* <!-- Hero End --> */}

      {/* <!-- Service Start --> */}
      <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container">
          <div className="row g-5 mb-5">
            <div
              className="col-lg-5 wow zoomIn"
              data-wow-delay="0.3s"
              style={{ minHeight: "400px" }}
            >
              <div className="twentytwenty-container position-relative h-100 rounded overflow-hidden">
                <img
                  className="position-absolute w-100 h-100"
                  src={afterImage}
                  style={{ objectFit: "cover" }}
                  alt="After Treatment"
                />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="section-title mb-5">
                <h5 className="position-relative d-inline-block text-primary text-uppercase">
                  Our Services
                </h5>
                <h1 className="display-5 mb-0">
                  Best Quality Drug Treatment Services
                </h1>
              </div>
              <div className="row g-5">
                <div
                  className="col-md-6 service-item wow zoomIn"
                  data-wow-delay="0.6s"
                >
                  <div className="rounded-top overflow-hidden">
                    <img
                      className="img-fluid"
                      src={service1}
                      alt="Ice Treatment"
                    />
                  </div>
                  <div className="position-relative bg-light rounded-bottom text-center p-4">
                    <h5 className="m-0">Ice Treatment (آئس علاج)</h5>
                    <p>
                      Symptoms: Anxiety, aggression, insomnia (بے چینی، غصہ، بے
                      خوابی)
                    </p>
                  </div>
                </div>
                <div
                  className="col-md-6 service-item wow zoomIn"
                  data-wow-delay="0.9s"
                >
                  <div className="rounded-top overflow-hidden">
                    <img
                      className="img-fluid"
                      src={service2}
                      alt="Hashish Treatment"
                    />
                  </div>
                  <div className="position-relative bg-light rounded-bottom text-center p-4">
                    <h5 className="m-0">Alcohol Treatment (شراب علاج)</h5>
                    <p>
                      Symptoms: Liver damage, dependency (جگر کا نقصان، انحصار)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row g-5 wow fadeInUp" data-wow-delay="0.1s">
            <div className="col-lg-12">
              <div className="row g-5">
                <div
                  className="col-md-4 service-item wow zoomIn"
                  data-wow-delay="0.3s"
                >
                  <div className="rounded-top overflow-hidden">
                    <img
                      className="img-fluid"
                      src={service3}
                      alt="Heroin Treatment"
                    />
                  </div>
                  <div className="position-relative bg-light rounded-bottom text-center p-4">
                    <h5 className="m-0">Heroin Treatment (ہیروئن علاج)</h5>
                    <p>
                      Symptoms: Severe addiction, withdrawal pains (شدید نشہ،
                      درد)
                    </p>
                  </div>
                </div>
                <div
                  className="col-md-4 service-item wow zoomIn"
                  data-wow-delay="0.6s"
                >
                  <div className="rounded-top overflow-hidden">
                    <img
                      className="img-fluid"
                      src={service4}
                      alt="Opium Treatment"
                    />
                  </div>
                  <div className="position-relative bg-light rounded-bottom text-center p-4">
                    <h5 className="m-0">Opium Treatment (افیون علاج)</h5>
                    <p>
                      Symptoms: Drowsiness, confusion (نیند میں خمار، الجھن)
                    </p>
                  </div>
                </div>
                {/* New Service Items */}
                <div
                  className="col-md-4 service-item wow zoomIn"
                  data-wow-delay="0.9s"
                >
                  <div className="rounded-top overflow-hidden">
                    <img
                      className="img-fluid"
                      src={service5}
                      alt="Alcohol Treatment"
                    />
                  </div>
                  <div className="position-relative bg-light rounded-bottom text-center p-4">
                    <h5 className="m-0">Hashish Treatment (چرس علاج)</h5>
                    <p>
                      Symptoms: Memory loss, lack of motivation (یادداشت کی کمی،
                      بے رغبتی)
                    </p>
                  </div>
                </div>
                <div
                  className="col-md-4 service-item wow zoomIn"
                  data-wow-delay="1.2s"
                >
                  <div className="rounded-top overflow-hidden">
                    <img
                      className="img-fluid"
                      src={service6}
                      alt="Cocaine Treatment"
                    />
                  </div>
                  <div className="position-relative bg-light rounded-bottom text-center p-4">
                    <h5 className="m-0">Cocaine Treatment (کوکین علاج)</h5>
                    <p>
                      Symptoms: Increased heart rate, paranoia (دل کی دھڑکن تیز،
                      وہم)
                    </p>
                  </div>
                </div>
                <div
                  className="col-md-4 service-item wow zoomIn"
                  data-wow-delay="1.2s"
                >
                  <div className="rounded-top overflow-hidden">
                    <img
                      className="img-fluid"
                      src={service7}
                      alt="Cocaine Treatment"
                    />
                  </div>
                  <div className="position-relative bg-light rounded-bottom text-center p-4">
                    <h5 className="m-0">
                      Sedative Treatment (نشہ آور ادویات کا علاج)
                    </h5>
                    <p>
                      Symptoms: Fatigue, slow breathing, dizziness (تھکن، سانس
                      کی رفتار میں کمی، چکر آنا)
                    </p>
                  </div>
                </div>
                <div
                  className="col-lg-4 service-item wow zoomIn"
                  data-wow-delay="0.9s"
                >
                  <div className="position-relative bg-primary rounded h-100 d-flex flex-column align-items-center justify-content-center text-center p-4">
                    <h3 className="text-white mb-3">Make Appointment</h3>
                    <p className="text-white mb-3">
                      Start your recovery today! <br /> Contact us to schedule
                      your appointment.
                    </p>
                    <h2 className="text-white mb-0">03009605574</h2>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-lg-12 service-item wow zoomIn" data-wow-delay="0.9s">
        <div className="position-relative bg-primary rounded h-100 d-flex flex-column align-items-center justify-content-center text-center p-4">
          <h3 className="text-white mb-3">Make Appointment</h3>
          <p className="text-white mb-3">Start your recovery today! <br /> Contact us to schedule your appointment.</p>
          <h2 className="text-white mb-0">03009605574</h2>
        </div>
      </div> */}
          </div>
        </div>
      </div>

      {/* <!-- Service End --> */}

      <Footer />
    </>
  );
}
